import { createSlice } from "@reduxjs/toolkit";
import { userLogin } from '../Actions/LoginAction'
import { userLogout } from "../Actions/LoginAction"; 
// keep unchecked
const userToken = null;
if (localStorage.getItem('userToken')) {
  userToken = localStorage.getItem('userToken') 
} else if (sessionStorage.getItem('userToken')) {
  userToken = sessionStorage.getItem('userToken')
}


const initialState = {
    loading: true,
    userInfo: {},
    userToken,
    error: null,
    success: false
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.userToken = payload.access_token;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [userLogout]: (state) => {
     return initialState
    },
  },
});

export const { resetState } = authSlice.actions; // Exporting reset action


export default authSlice.reducer