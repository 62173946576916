import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../constant/APIConstants';
import "../pages/Login/Login.css"
import Swal from "sweetalert2";

export const userLogin = createAsyncThunk(
  "/v1/auth/token",
  async ({ Email, Password, keepSignIn }, { rejectWithValue }) => {
    const url = `${API_URL}/token`;
    const requestHeaders = {
      user: Email,
      password: Password
    };

    const data = fetch(url, {
      method: 'POST',
      headers: requestHeaders,
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message);
          })
        }

        return response.json();
      })
      .then((data) => {
        localStorage.removeItem("authToken")
        sessionStorage.removeItem("authToken  ")
        if(keepSignIn) {
          localStorage.setItem('authToken', data.access_token);
          localStorage.setItem("ApiKey", data.api_key);
        } else {
          sessionStorage.setItem('authToken', data.access_token);
          sessionStorage.setItem("ApiKey", data.api_key);
        }
        
        return data

      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          backdrop: "rgba(0, 0, 0, 0.85)",
          icon: "error",
          customClass: {
            title: 'sweetalert-color',
            htmlContainer: 'sweetalert-color',
            content: 'sweetalert-color'
          },
        });

        
        
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      });
    return data


  }
);



export const userLogout = createAsyncThunk(
  '/v1/auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      console.log("in log out......", sessionStorage.getItem("authToken"), sessionStorage.getItem("ApiKey"))
      localStorage.removeItem('authToken');
      localStorage.removeItem("ApiKey");
      sessionStorage.removeItem('authToken');
      sessionStorage.removeItem('ApiKey');
      return {};
    } catch (error) {
      console.log("error:",error)
      return rejectWithValue(error.message);
    }
  }
);
