import React from 'react';
import { checkUserToken } from '../utils/CheckUserToken';
import { useEffect, useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { userLogout } from "../Actions/LoginAction";
import { useDispatch, useSelector } from "react-redux";

import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { scroller } from 'react-scroll';
import Button from "@mui/material/Button";
import { Dialog, DialogContent, DialogContentText } from '@mui/material';
import { ENV, EU_URL, NA_URL } from '../constant/APIConstants';
import Popover from "@mui/material/Popover";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./Navbar.css";
import { UserData } from "../Actions/UsersData";
import { Link } from 'react-router-dom';

const Navigationbar = () => {
  const PopoverStyle = {
    top: "48px",
    right: "30px",
    width: "150%",
  };
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [buttonClicked, setButtonClicked] = useState(false)
  const [profileClicked, setProfileClicked] = useState(false);
  const [anchor, setAnchor] = useState("");
  const scrollRef = useRef(null);
  const userData = useSelector((state) => state.UserData);
  useEffect(() => {
    const tokenData = sessionStorage.getItem("authToken") || localStorage.getItem("authToken")
    if (tokenData && !Object.keys(userData.UserData).length){
      dispatch(UserData(tokenData));
    }
  }, [dispatch, userData.UserData]);
  const userName = userData.UserData.first_name ? `${userData.UserData.first_name} ${userData.UserData.last_name}` : "User"
  const handleLogout = async () => {
    setProfileClicked(false);
    await dispatch(userLogout());
    navigate("/login");
  };

  const handleProfile = async () => {
    setProfileClicked(false);
    navigate("/Profile");
  };
  
  const handleSelectRegion = async () => {
    if (ENV != "dev") {
      handleOpen();
    } else {
      window.location = '/login';
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  }

  const handleOpen = () => {
    setOpenDialog(true);
  }

  const handleRedirect = (region_url) => {
    navigate(`/login` , {replace:true});
    handleClose()
  }

  const [LoggedInState, setLoggedInState] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState('');
  const [IfMobilisight, setIfMobilisight] = useState(false)
  const [IfMobilisightexP, setIfMobilisightexP] = useState(false)
  const [IfMobilisightexFApi, setIfMobilisightexFApi] = useState(false)
  const [IfMobilisightDatacatalogue, setIfMobilisightDatacatalogue] = useState(false)

  useEffect(() => {
    if (checkUserToken()) {
      setLoggedInState(true);
    } else {
      setLoggedInState(false)
    }
    let path = location.pathname.toLowerCase()
    path = path.endsWith("/") ? path.slice(0, -1) : path;

    setIfMobilisight(path.toLowerCase().endsWith('/mobilisight'))
    setIfMobilisightexFApi(path.toLowerCase().endsWith('/mobilisightexfapis'))
    setIfMobilisightexP(path.toLowerCase().endsWith('/mobilisight/expdatacatalogue'))
    setIfMobilisightDatacatalogue(path.toLowerCase().endsWith('/mobilisight/exfdatacatalogue'))


  }, [navigate]);

  useEffect(() => {
    if (localStorage['indexBrandsPageButtonClicked'] === 'true') {
      setButtonClicked(true);
      localStorage.removeItem('indexBrandsPageButtonClicked')
    }
  }, [])

  useEffect(() => {
    if (buttonClicked) {
      if (location.pathname != '/') {
        localStorage.setItem('indexBrandsPageButtonClicked', 'true')
        window.location.href = '/';
      }
    }

  }, [buttonClicked])

  useEffect(() => {
    var targetDiv = document.getElementById('index-brands-scroll')
    if (targetDiv && buttonClicked && scrollRef.current) {
      scroller.scrollTo('index-brands-scroll', {
        duration: 80,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
      setButtonClicked(false);
    }

  }, [buttonClicked, scrollRef]);

  const brandsSection = () => {
    setButtonClicked(true);
  }

  const onProfileClick = (e) => {
    e.preventDefault();
    setProfileClicked(true);
    setAnchor(e.currentTarget);
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogContentText>
          <div className="p-4">
            <p>Please select the region you want to Login:{"\n"}</p>
            <Button onClick={() => handleRedirect(NA_URL)}>
              North America
            </Button>
            <Button onClick={() => handleRedirect(EU_URL)}>
              Europe
            </Button>
          </div>
        </DialogContentText>
        <DialogContent>
          <Button className="btn btn-primary" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
      {" "}
      <Navbar
        expand="lg"
        style={{ backgroundColor: "#243782", zIndex: "100" }}
        className="ps-4 pe-4"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Link className="mr-auto" to="/" >
            <img
              src={'/images/stellantis_logo.png'}
              alt="Stellantis Developers Logo"
            />{" "}
          </Link>
          <Nav className="ms-auto ml-auto">
            {IfMobilisight || IfMobilisightDatacatalogue || IfMobilisightexP || IfMobilisightexFApi ? <a className='btn' onClick={()=> navigate("/Explore/Mobilisight/exPDataCatalogue")}style={{color:"#ffffff", margin:"0px"}}>exP Data Catalogue</a>:""}
            {IfMobilisight || IfMobilisightDatacatalogue || IfMobilisightexP || IfMobilisightexFApi ? <a className='btn' onClick={()=> navigate("/Explore/Mobilisight/exFDataCatalogue")} style={{color:"#ffffff", margin:"0px"}}>exF Data Catalogue</a>:""}
            {IfMobilisight || IfMobilisightDatacatalogue || IfMobilisightexP || IfMobilisightexFApi ? <a className='btn' onClick={()=> navigate("/Explore/Mobilisight/MobilisightexFAPIs")} style={{color:"#ffffff", margin:"0px"}}>Mobilisights exF APIS</a>:""}

            <Nav.Link onClick={()=> navigate("/Explore")}>Explore</Nav.Link>
            {!LoggedInState ? (

                <Nav.Link onClick={handleSelectRegion}>Login</Nav.Link>

            ) : (
              <div style={{ display: "flex", paddingLeft: "7px" }}>
                <div style={{ float: "left", marginTop: "3px" }}>
                  <img
                    style={{
                      width: "27px",
                      height: "27px",
                      cursor: "pointer",
                    }}
                    src={'/images/user1.png'}
                    onClick={(e) => {
                      onProfileClick(e);
                    }}
                  />
                  <Popover
                    open={profileClicked}
                    anchorEl={anchor}
                    style={PopoverStyle}
                    onClose={() => {
                      setProfileClicked(false);
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          paddingLeft: "15px",
                          paddingRight: "15px",
                          borderBottom: "#e4dfdf solid 0.1rem",
                        }}
                      >
                        {userName}
                      </div>
                      <MenuItem onClick={handleProfile}>Profile</MenuItem>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </div>
                  </Popover>
                </div>
              </div>
            )}
          </Nav>

        </Navbar.Collapse>
      </Navbar>
    </>
  );
}



export default Navigationbar;