import React from "react";
import { useDispatch,useSelector } from "react-redux";
import { Navigate, Route, useLocation } from "react-router-dom";
// import { Navigate,  } from "react-router-dom";
import Title from "../components/Title";
import Headers from "../components/Headers/Headers";
import { checkUserToken } from "./CheckUserToken";
import "../pages/Login/Login.css";

const ProtectedRoute = ({ children, sideNav, redirectPath, addHeaders }) => {
  const auth = checkUserToken();
  let location = useLocation();
  const displayHeaders = addHeaders !== undefined ? addHeaders : true;
 
  if (!auth) {
    localStorage.removeItem("authToken")
    sessionStorage.removeItem("authToken")
      return <Navigate to={redirectPath ? redirectPath : "/login"} state={{ from: location }} replace />;
  }


  return (
    <>
      <div className="container-fluid" style={{minHeight:"82vh"}}>
        <div className="row">
          {
            displayHeaders ? <Headers /> : <></>
          }
          <div
            className="col"
            style={{ background: "white", overflow: "auto", padding: "0px" }}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );

};

export default ProtectedRoute;
