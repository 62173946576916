import React from "react";
import { useForm } from "react-hook-form";
import BackGroundVideoFrame from "../../components/BackgroundVideo/BackGroundVideoFrame";
import { UpdateAccountPasswordApiCallback } from "../UpdateAccountPassword/UpdateAccountPasswordApiCallback";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
// import SLogo from "../../images/stellantis_image.png";
import '../Register/style.css'


const ChangeTempPassword = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm();
  const navigate = useNavigate()
  const onSubmit = (data) => {
   
      const datar = UpdateAccountPasswordApiCallback(data, navigate)
  }

  return (
    <>
      <div className="Register-page">
        <form onSubmit={handleSubmit(onSubmit)} className="p-2">
          <Container className="Register-container">
          <div style={{display: "flex", "flex-direction": "column","flex-wrap": "wrap"}}>

            <img src={'/images/stellantis_image.png'} alt="SLogo" className="img-fluid" style={{ objectFit: "contain" }} />
            </div>
            <h2 className="p-3" style={{ color: "white", textAlign: "center" }}>Set password</h2>
            <div className="form-group">
              <label for="connectedAccountEmail">
                Username/Email <span className="text-danger">*</span>
              </label>
              <input
                required
                type="text"
                className="form-control"
                id="connectedAccountEmail"
                {...register("Email")}
                placeholder="Enter User Name"
              />
            </div>
            <div className="form-group">
              <label for="userPassword">
                Temporary Password<span className="text-danger">*</span>
              </label>
              <input
                required
                type="password"
                className="form-control"
                id="currentPassword"
                {...register("CurrentPassword", {
                  required: "Password is required",
                })}
                placeholder="Enter current password"
                autoComplete="current-password"
              />
              {errors?.CurrentPassword && (
                <p>{errors.CurrentPassword.message}</p>
              )}
            </div>
            <div className="form-group">
              <label for="password">
                New Password<span className="text-danger">*</span>
              </label>
              <input
                required
                type="password"
                className="form-control"
                name="password"
                id="password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                })}
                placeholder="Enter new password"
                autoComplete="new-password"
              />
              {errors?.password && <p>{errors.password.message}</p>}
            </div>
            <div className="form-group">
              <label for="confirmPassword">
                Confirm account Password
                <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                className="form-control"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm new password"
                autoComplete="  Confirm New-password"
                {...register("confirmPassword", {
                  required: "Confirm Password is required",
                  validate: (value) =>
                    value === watch("password") || "Passwords do not match",
                })}
              />
              {errors?.confirmPassword && (
                <p>{errors.confirmPassword.message}</p>
              )}
            </div>
            <div className="form-group text-center">
            <button type="submit" className="signin-button" variant="light">
                Submit
              </button>
            </div>
        </Container>
      </form>
    </div >
    </>
  );
};

export default ChangeTempPassword;
